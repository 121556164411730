const RECORD_LIMIT = process.env.REACT_APP_RECORD_LIMIT

export const getRecordCount = (metrics, datasource) => {
  if (!datasource) {
    return null
  }

  if (datasource.type === 'api') return null

  let count = 0

  for (const tableId of Object.keys(datasource.tables)) {
    count += metrics[tableId] || 0
  }

  return count
}

export const getPercentageFull = (metrics, datasource) => {
  const count = getRecordCount(metrics, datasource)

  if (count === null) {
    return null
  }

  const percentNum = Math.min(100, (100 * count) / RECORD_LIMIT)

  if (Number.isNaN(percentNum)) {
    return null
  }

  const percentage = Math.floor(percentNum).toFixed(0)

  return `${percentage}%`
}

export const getRecordsUsed = (metrics, datasource) => {
  const count = getRecordCount(metrics, datasource)

  if (count === null) {
    return null
  }

  return `${count} of ${RECORD_LIMIT}`
}

export const getIsOverLimit = (metrics, datasource) => {
  const count = getRecordCount(metrics, datasource)

  if (count === null) {
    return false
  }

  return count >= 200
}
